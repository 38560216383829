import * as React from 'react'
import { Button, button } from '../components/button'
import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-parallax'
import { Container } from '../components/container'
import { useTheme } from '@conte-ltd/components-theme'
import type { Theme } from '../styles/theme'
import { Hero } from '../components/hero'
import { Separator } from '@conte-ltd/components-separator'
import { Fragment } from 'react'
import { Icon } from '../components/icon'
import { InViewAnimation } from '@conte-ltd/components-animation'
import { useMediaQuery, useDidUpdateEffect } from '@conte-ltd/components-utils'
import { InViewFadeUp } from '../components/in-view-fade'
import { SlideRightUpAnimation } from '../components/slide-right-up-animation'
import { InViewAnimationController } from '../components/in-view-animation-conttoller'
import { SlideDownAnimation } from '../components/slide-down-animation'
import { FadeInAnimation } from '../components/fade-in-animation'
import { SlideLeftDownAnimation } from '../components/slide-left-down-animation'
import { SlideRightAnimation } from '../components/slide-right-animation'
import { Layout } from '../components/layout'
import { Company } from '../components/company'
import { ToContact } from '../components/to-contact'
import { Section } from '../components/section'
import { graphql, PageProps } from 'gatsby'
import { TopPageQuery } from '../../graphql-types'
import { WorksGallery } from '../components/works-gallery'

const IndexPage = ({ data }: PageProps<TopPageQuery>) => {
  const {
    variables: {
      color,
      spacing,
      fontFamily,
      fontSize,
      fontWeight,
      letterSpacing,
      lineHeight,
    },
    styles: { padding },
    breakpoints: bp,
  } = useTheme<Theme>()
  const isMobile = useMediaQuery(bp.lg)
  const isDesktop = !isMobile

  const fadeInSettings = {
    fadeIn: true,
    options: { duration: 500 },
    threshold: 0.2,
    triggerOnce: true,
  }

  return (
    <Layout id={'wrapper'}>
      <Hero />
      <Parallax
        renderLayer={percentage => {
          const topOffset = isMobile ? 0 : 200 - 100 * percentage

          return (
            <Section
              id={'about'}
              className={'light-mode'}
              css={css`
                padding-top: ${spacing['4xl']};

                @media ${bp.lg} {
                  padding-top: ${spacing['2xl']};
                }
              `}
            >
              <Container
                css={css`
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  gap: 1.6rem;

                  @media ${bp.lg} {
                    grid-template-columns: 1fr;
                  }
                `}
              >
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  <h1
                    css={css`
                      margin: calc(${spacing.offset.xs} * -1) 0;
                    `}
                  >
                    <InViewFadeUp
                      css={css`
                        display: inline-block;
                        margin-bottom: calc(${spacing.offset.xs} * -1);
                        font-size: ${fontSize['8xl']};
                        line-height: ${lineHeight.normal};
                      `}
                    >
                      CREATIVE PARTNER.
                    </InViewFadeUp>
                  </h1>

                  <h2
                    css={css`
                      display: inline-block;
                      margin-top: ${spacing['2xl']};
                      font-family: ${fontFamily.jp};
                      font-weight: ${fontWeight.semibold};
                      font-size: ${fontSize['2xl']};
                      letter-spacing: ${letterSpacing.normal};

                      @media ${bp.lg} {
                        margin-top: ${spacing.lg};
                        font-size: ${fontSize.lg};
                      }
                    `}
                  >
                    <InViewFadeUp>次の一手の創り手。</InViewFadeUp>
                  </h2>
                </div>

                <div
                  css={css`
                    position: relative;
                    padding: ${spacing['4xl']} 0;

                    @media ${bp.lg} {
                      padding: ${spacing.xl} 0 ${spacing['4xl']};
                    }
                  `}
                  style={{
                    top: topOffset,
                  }}
                >
                  <p>
                    <InViewFadeUp>
                      企業・サービス・商品、
                      <br />
                      生み出すものには強い想いがあるはず。
                    </InViewFadeUp>
                    <br />
                    <br />
                    <InViewFadeUp>
                      CONTEはその「想い」をカタチにして、
                      <br />
                      多くの人へ伝えるお手伝いをしています。
                    </InViewFadeUp>
                    <br />
                    <br />
                    <InViewFadeUp>
                      どのようにカタチにするか、どのように伝えるのか。
                    </InViewFadeUp>
                    <br />
                    <br />
                    <InViewFadeUp>
                      「想いはあるけれど、どうしたらいいのかわからない」。
                      <br />
                      私たちは、そんなお客さまの気軽に相談できる創り手＝
                      {isDesktop && <br />}
                      「クリエイティブパートナー」でありたいと思っています。
                    </InViewFadeUp>
                    <br />
                    <br />
                    <InViewFadeUp>
                      納品して終わりではなく、次の一手を見据えた頼れる相方として
                      {isDesktop && <br />}
                      一緒にゴールを目指していきます。
                    </InViewFadeUp>
                  </p>

                  <div
                    css={css`
                      margin-top: ${spacing['2xl']};
                      text-align: right;
                    `}
                  >
                    <Button
                      css={[button(color).secondary]}
                      variant={'underline'}
                      href={'/about'}
                    >
                      VIEW MORE
                    </Button>
                  </div>
                </div>
              </Container>
            </Section>
          )
        }}
      />

      <Section
        id={'works'}
        css={[
          css`
            background-color: ${color.lightgray};
          `,
          padding.y['5xl'],
        ]}
      >
        <Container>
          <h2>
            <InViewFadeUp>WORKS</InViewFadeUp>
          </h2>

          <WorksGallery
            nodes={data.works.nodes.filter(
              (_, index) => isDesktop || index < 4
            )}
          />

          <div
            css={css`
              margin-top: ${spacing['2xl']};
              text-align: right;

              @media ${bp.md} {
                margin-top: ${spacing['xl']};
              }
            `}
          >
            <Button
              css={[button(color).secondary]}
              variant={'underline'}
              href={'/works'}
            >
              ALL WORKS
            </Button>
          </div>
        </Container>
      </Section>

      <Section id={'service'}>
        <Container
          css={css`
            display: grid;
            grid-template-columns: 60% 40%;
            padding: ${spacing['5xl']} 0 ${spacing['4xl']};

            @media ${bp.lg} {
              grid-template-columns: 1fr;
            }
          `}
        >
          <Parallax
            renderLayer={percentage => {
              const topOffset = isMobile ? 0 : -100 - 100 * percentage

              return (
                <div
                  css={css`
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: ${spacing.md};
                    padding: ${spacing['5xl']} 0 ${spacing['3xl']};

                    @media ${bp.xl} {
                      gap: ${spacing.lg};
                      padding: ${spacing['5xl']} 0 0;
                    }

                    @media ${bp.lg} {
                      gap: ${spacing.lg};
                      padding: 0;
                    }
                  `}
                  style={{
                    top: topOffset,
                  }}
                >
                  <h2>
                    <InViewFadeUp>SERVICE</InViewFadeUp>
                  </h2>

                  <p>
                    <InViewFadeUp>
                      Webサイトや印刷物等のグラフィック、動画コンテンツなど
                      {isDesktop && <br />}
                      ブランディングを軸にした制作物で、
                      {isDesktop && <br />}
                      企業・サービス・商品の魅力を伝えます。
                    </InViewFadeUp>
                    <br />
                    <br />
                    <InViewFadeUp>
                      CONTEにはそれぞれの領域のプロが在籍しているため、
                      {isDesktop && <br />}
                      お客さまの想いに合ったモノをトータルで提案いたします。
                    </InViewFadeUp>
                  </p>

                  {isDesktop && (
                    <Button
                      css={css`
                        margin-top: ${spacing.md};
                      `}
                      variant={'underline'}
                      href={'/service'}
                    >
                      VIEW MORE
                    </Button>
                  )}
                </div>
              )
            }}
          />

          <div
            css={css`
              align-self: center;
              position: relative;
              width: 50vw;
              max-width: 85.5rem;
              height: 55.5rem;
              overflow: hidden;

              @media ${bp.lg} {
                width: 100%;
                max-width: unset;
                margin: 6rem 0;
              }

              @media ${bp.md} {
                height: 22rem;
              }
            `}
          >
            <StaticImage
              src={'../images/offshot_01.jpg'}
              alt={'CONTEキービジュアルオフショット'}
              objectFit={'cover'}
              objectPosition={`center`}
              css={css`
                position: relative;
                top: 0;
                width: 100%;
              `}
            />
          </div>

          <InViewAnimation {...fadeInSettings}>
            <div
              css={css`
                grid-column: span 2;
                padding-bottom: ${spacing['4xl']};

                @media ${bp.lg} {
                  grid-column: unset;
                  padding-bottom: unset;
                }
              `}
            >
              <InViewAnimationController refsNum={7}>
                {({ intersectionRef, refs, controllers, inView }) => {
                  const delay = 800

                  const Animation1 = isMobile ? Fragment : SlideDownAnimation
                  const animation1Settings = isMobile
                    ? {}
                    : ({
                        ref: refs[0],
                        moveAmount: '38%',
                      } as const)

                  useDidUpdateEffect(() => {
                    const rate = inView ? 1 : -1
                    controllers.forEach(({ play, updatePlaybackRate }) => {
                      updatePlaybackRate(rate)
                      play()
                    })
                  }, [isMobile])

                  return (
                    <section
                      css={css`
                        position: relative;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        row-gap: ${spacing.sm};
                        column-gap: ${spacing['3xs']};
                        color: ${color.black};

                        @media ${bp.lg} {
                          grid-column: span 1;
                          grid-template-columns: 1fr;
                          row-gap: ${spacing.xs};
                        }
                      `}
                    >
                      <div
                        css={css`
                          position: absolute;
                          bottom: 0;

                          @media ${bp.lg} {
                            bottom: 30%;
                          }
                        `}
                        ref={intersectionRef}
                      />

                      <Animation1 {...animation1Settings}>
                        <section
                          css={css`
                            position: relative;
                            grid-column: span 3;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: ${spacing.xs};
                            text-align: center;
                            color: ${color.black};
                            background: ${color.lightgray};
                            padding: ${spacing.sm};

                            @media ${bp.lg} {
                              grid-column: unset;
                              padding: ${spacing.sm} ${spacing['2xs']};
                            }
                          `}
                        >
                          <h3
                            css={css`
                              font-size: ${isMobile
                                ? fontSize['2lg']
                                : fontSize.xl};
                            `}
                          >
                            BRANDING
                            <br />
                            <small
                              css={css`
                                display: inline-block;
                                margin-top: ${spacing['3xs']};

                                @media ${bp.lg} {
                                  margin-top: ${spacing['2xs']};
                                }
                              `}
                            >
                              ブランディング
                            </small>
                          </h3>

                          <ul
                            css={css`
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              gap: ${spacing.xs};
                              margin: unset;

                              @media ${bp.lg} {
                                gap: unset;

                                & > li:not(:first-of-type) {
                                  padding-left: ${spacing['2xs']};
                                  border-left: solid 1px;
                                }

                                & > li:not(:last-of-type) {
                                  padding-right: ${spacing['2xs']};
                                }
                              }
                            `}
                          >
                            {['Naming', 'Logo', 'Photo'].map(item => (
                              <li
                                key={item}
                                css={css`
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  width: 112px;
                                  height: 38px;
                                  background-color: ${color.white};
                                  font-size: ${fontSize.md};

                                  @media ${bp.lg} {
                                    width: fit-content;
                                    height: auto;
                                    background-color: unset;
                                  }
                                `}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </section>
                      </Animation1>

                      {[
                        {
                          id: 'web',
                          heading: 'WEB',
                          subheading: 'ウェブ制作',
                          contents: [
                            'コーポレートサイト',
                            'ECサイト',
                            'プロモーションサイト',
                            'ランディングページ',
                          ],
                        },
                        {
                          id: 'graphic',
                          heading: 'GRAPHIC',
                          subheading: 'グラフィック制作',
                          contents: [
                            '名刺',
                            'ポスター',
                            'パンフレット/カタログ',
                            'パッケージ',
                          ],
                        },
                        {
                          id: 'movie',
                          heading: 'VIDEO',
                          subheading: '動画制作',
                          contents: [
                            'サイネージコンテンツ',
                            '企業動画',
                            'WEB動画',
                            'CM',
                          ],
                        },
                      ].map(({ id, heading, subheading, contents }, index) => {
                        let Animation
                        let animationOptions

                        if (isMobile) {
                          Animation =
                            index === 0
                              ? SlideRightAnimation
                              : index === 1
                              ? SlideDownAnimation
                              : SlideLeftDownAnimation
                          animationOptions =
                            index === 1
                              ? ({
                                  moveAmount: '74%',
                                  options: {
                                    duration: 500,
                                  },
                                  ref: refs[index + 1],
                                } as const)
                              : index === 2
                              ? ({
                                  pattern: 2,
                                  ref: refs[index + 1],
                                } as const)
                              : ({
                                  options: {
                                    duration: 300,
                                  },
                                  ref: refs[index + 1],
                                } as const)
                        } else {
                          Animation =
                            index === 0
                              ? SlideLeftDownAnimation
                              : index === 1
                              ? Fragment
                              : SlideRightUpAnimation
                          animationOptions =
                            index === 1
                              ? ({} as const)
                              : ({
                                  ref: refs[index + 1],
                                } as const)
                        }

                        return (
                          <div
                            key={index}
                            css={css`
                              position: relative;
                            `}
                          >
                            <FadeInAnimation
                              ref={refs[index + 4]}
                              options={{
                                duration: 200,
                                delay,
                              }}
                            >
                              <Separator
                                orientation={'vertical'}
                                css={[
                                  css`
                                    --c-separator-size: ${spacing.sm};
                                    position: absolute;
                                    top: calc(var(--c-separator-size) * -1);
                                    left: 50%;
                                    color: ${color.black};

                                    @media ${bp.lg} {
                                      --c-separator-size: ${spacing.xs};
                                    }
                                  `,
                                ]}
                              />
                            </FadeInAnimation>

                            <Animation {...animationOptions}>
                              <Button
                                variant={'text'}
                                key={id}
                                href={`/service#${id}`}
                                css={css`
                                  display: block;
                                  width: 100%;
                                  text-align: unset;
                                `}
                              >
                                <section
                                  css={css`
                                    flex-grow: 1;
                                    position: relative;
                                    display: flex;
                                    flex-direction: column;
                                    gap: ${spacing.sm};
                                    height: 37rem;
                                    padding: ${spacing.sm};
                                    background: ${color.lightgray};

                                    @media ${bp.lg} {
                                      flex-direction: row;
                                      gap: ${spacing.xs};
                                      height: unset;
                                      padding: ${spacing.xs};
                                    }

                                    @media ${bp.md} {
                                      gap: ${spacing['2xs']};
                                      padding: ${spacing.xs} ${spacing['2xs']};
                                    }
                                  `}
                                >
                                  <h3
                                    css={css`
                                      align-self: center;
                                      text-align: center;
                                      font-size: ${isMobile
                                        ? fontSize['2lg']
                                        : fontSize.xl};

                                      @media ${bp.lg} {
                                        flex-basis: 9.5rem;
                                        align-self: flex-start;
                                        text-align: left;
                                      }

                                      @media ${bp.md} {
                                        letter-spacing: ${letterSpacing.tight};
                                      }
                                    `}
                                  >
                                    {heading}
                                    <br />
                                    <small
                                      css={css`
                                        display: inline-block;
                                        margin-top: ${spacing['2xs']};

                                        @media ${bp.lg} {
                                          font-size: ${fontSize.xs};
                                        }
                                      `}
                                    >
                                      {subheading}
                                    </small>
                                  </h3>

                                  {isDesktop && (
                                    <Separator
                                      css={css`
                                        --c-separator-weight: 3px;
                                        color: ${color.primary};
                                      `}
                                    />
                                  )}

                                  <ul
                                    css={css`
                                      display: flex;
                                      flex-direction: column;
                                      gap: calc(${spacing['3xs']} - 0.2em);

                                      @media ${bp.lg} {
                                        font-size: ${fontSize.sm};
                                      }
                                    `}
                                  >
                                    {contents.map(content => (
                                      <li key={content}>
                                        <Icon
                                          type={'check-box'}
                                          css={css`
                                            margin-right: ${spacing.offset.lg};
                                            font-size: 1.2em;
                                            vertical-align: -10%;
                                            color: ${color.primary};

                                            @media ${bp.md} {
                                              margin-right: ${spacing.offset
                                                .sm};
                                            }
                                          `}
                                        />
                                        {content}
                                      </li>
                                    ))}
                                  </ul>
                                </section>

                                <div
                                  css={[
                                    css`
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                      width: 100%;
                                      height: 4rem;
                                      color: ${color.white};
                                      background-color: ${color.black};
                                      font-size: 1.2rem;

                                      @media ${bp.md} {
                                        font-size: 1rem;
                                      }
                                    `,
                                  ]}
                                >
                                  <Icon type="angle-down" />
                                </div>
                              </Button>
                            </Animation>
                          </div>
                        )
                      })}
                    </section>
                  )
                }}
              </InViewAnimationController>
            </div>
          </InViewAnimation>

          {isMobile && (
            <InViewAnimation>
              <Button
                css={[
                  button(color).secondary2,
                  css`
                    margin-top: ${spacing['2xl']};
                    justify-self: center;

                    @media ${bp.lg} {
                      margin-top: ${spacing['md']};
                    }
                  `,
                ]}
                variant={'underline'}
                href={'/service'}
              >
                VIEW MORE
              </Button>
            </InViewAnimation>
          )}
        </Container>
      </Section>

      <div
        css={css`
          background-color: ${color.lightgray};
          padding: ${spacing['5xl']} 0;
        `}
      >
        <Parallax
          renderLayer={percentage => {
            const scale = Math.floor(100000 + percentage * 5000) / 100000
            const translateY = 200 - Math.floor(percentage * 100)

            return (
              <div
                css={css`
                  position: relative;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  gap: ${spacing['3xs']};
                  width: 300vw;
                  margin: 0 calc(50% - 150vw);
                `}
              >
                {isDesktop && (
                  <div
                    css={css`
                      position: relative;
                      overflow: hidden;
                      width: 1364px;
                      height: 1000px;
                    `}
                  >
                    <div
                      css={css`
                        position: relative;
                        top: -200px;
                        width: 100%;
                        height: 100%;
                      `}
                      style={{
                        transform:
                          translateY < 0
                            ? 'translateY(0)'
                            : `translateY(${translateY}px)`,
                      }}
                    >
                      <StaticImage
                        src={'../images/subcut_04.jpg'}
                        alt={'蒲郡の自然'}
                        objectFit={'cover'}
                        css={css`
                          width: 100%;
                          height: 120%;
                          filter: grayscale(1);
                        `}
                      />
                    </div>
                  </div>
                )}

                {isMobile ? (
                  <div
                    css={css`
                      position: relative;
                      overflow: hidden;
                      width: 100vw;
                      height: 500px;
                    `}
                  >
                    <div
                      css={css`
                        width: 100%;
                        height: 100%;
                        transform-origin: center bottom;
                      `}
                      style={{
                        transform:
                          scale > 1.1 ? 'scale(1.1)' : `scale(${scale})`,
                      }}
                    >
                      <StaticImage
                        src={'../images/keyvisual_02_sp.jpg'}
                        alt={'CONTEキービジュアルその２'}
                        layout={'fullWidth'}
                        quality={100}
                        objectFit={'cover'}
                        css={css`
                          width: 100%;
                          height: 100%;
                        `}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    css={css`
                      position: relative;
                      overflow: hidden;
                      width: 1364px;
                      height: 1000px;
                    `}
                  >
                    <div
                      css={css`
                        width: 100%;
                        height: 100%;
                        transform-origin: center bottom;
                      `}
                      style={{
                        transform:
                          scale > 1.1 ? 'scale(1.1)' : `scale(${scale})`,
                      }}
                    >
                      <StaticImage
                        src={'../images/keyvisual_02.jpg'}
                        alt={'CONTEキービジュアルその２'}
                        quality={100}
                        objectFit={'cover'}
                        css={css`
                          width: 100%;
                          height: 100%;
                        `}
                      />
                    </div>
                  </div>
                )}

                {isDesktop && (
                  <div
                    css={css`
                      position: relative;
                      overflow: hidden;
                      width: 1364px;
                      height: 1000px;
                    `}
                  >
                    <div
                      css={css`
                        position: relative;
                        top: -200px;
                        width: 100%;
                        height: 100%;
                      `}
                      style={{
                        transform:
                          translateY < 0
                            ? 'translateY(0)'
                            : `translateY(${translateY}px)`,
                      }}
                    >
                      <StaticImage
                        src={'../images/subcut_05.jpg'}
                        alt={'蒲郡の自然'}
                        objectFit={'cover'}
                        css={css`
                          width: 100%;
                          height: 120%;
                          filter: grayscale(1);
                        `}
                      />
                    </div>
                  </div>
                )}
              </div>
            )
          }}
        />

        <Section
          css={css`
            padding-top: ${spacing['2xl']};

            @media ${bp.lg} {
              padding-top: ${spacing.xl};
            }
          `}
        >
          <Container
            css={css`
              position: relative;
              display: flex;
              flex-direction: column;
              gap: ${spacing.xl};
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing.md};

                @media ${bp.lg} {
                  gap: ${spacing.sm};
                }
              `}
            >
              <h2
                css={css`
                  font-size: ${fontSize['2xl']};
                  font-weight: ${fontWeight.medium};
                  letter-spacing: ${letterSpacing.tighter};

                  @media ${bp.lg} {
                    margin: ${spacing.offset.negative.xs} 0;
                    line-height: ${lineHeight.relaxed};
                  }
                `}
              >
                <InViewFadeUp>体験へつながるデザイン</InViewFadeUp>
              </h2>

              <p>
                <InViewFadeUp>
                  CONTEはデザインにこだわってすべてのコンテンツを制作します。
                  <br />
                  「カッコいい」「美しい」「お洒落」に見た目を整えるだけではなく、
                  {isDesktop && <br />}
                  「使いやすい」「分かりやすい」「心地よく感じる」など、心を動かすユーザー体験を大切にしたいと考えています。
                </InViewFadeUp>
              </p>
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing.md};

                @media ${bp.lg} {
                  gap: ${spacing.sm};
                }
              `}
            >
              <h2
                css={css`
                  font-size: ${fontSize['2xl']};
                  font-weight: ${fontWeight.medium};
                  letter-spacing: ${letterSpacing.tighter};

                  @media ${bp.lg} {
                    margin: ${spacing.offset.negative.xs} 0;
                    line-height: ${lineHeight.relaxed};
                  }
                `}
              >
                <InViewFadeUp>想いを大切にします</InViewFadeUp>
              </h2>

              <p>
                <InViewFadeUp>
                  目的や戦略によって、必要な制作物はさまざまです。
                  <br />
                  ゴールを見据えてデザインやコンセプトを一貫させ、
                  {isDesktop && <br />}
                  印象に残る制作物を発信することでブランドのメッセージをより真っ直ぐに、強くします。
                  <br />
                  まずは伝えたい想いを私たちにお聞かせください。
                </InViewFadeUp>
              </p>
            </div>
          </Container>
        </Section>
      </div>

      <Company />

      <Section
        className={'light-mode'}
        css={css`
          padding-top: ${spacing['4xl']};
          padding-bottom: ${spacing['4xl']};
          text-align: center;
        `}
      >
        <Container>
          <div
            css={css`
              flex-shrink: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: ${spacing.md};

              @media ${bp.lg} {
                gap: ${spacing.lg};
              }
            `}
          >
            <h2
              css={css`
                font-size: ${fontSize['2xl']};
                font-weight: ${fontWeight.medium};
                letter-spacing: ${letterSpacing.tighter};
                line-height: ${lineHeight.relaxed};
              `}
            >
              <InViewFadeUp>
                「創りたい」を
                {isMobile && <br />}
                ご相談ください。
              </InViewFadeUp>
            </h2>
            <p>
              <InViewFadeUp>
                CONTEでは、{isMobile && <br />}
                困っている！やりたい！つくりたい！{isMobile && <br />}といった
                {isDesktop && <br />}
                漠然とした想いから{isMobile && <br />}
                最適なモノをご提案をいたします。
                <br />
                そのワクワクした{isMobile && <br />}気持ちが大好きです。
                <br />
                まずはお気軽にご相談ください。
              </InViewFadeUp>
            </p>
          </div>
        </Container>
      </Section>

      <ToContact />
    </Layout>
  )
}

export const query = graphql`
  query TopPage {
    works: allMdx(
      filter: { slug: { glob: "works/*" } }
      sort: { fields: frontmatter___order, order: ASC }
      limit: 6
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          subtitle
          categories
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          url
        }
      }
    }
  }
`

export default IndexPage
